<template>
  <div>
    <!-- offcanvas Repeaters -->
    <div
      id="offcanvasRepeaters"
      class="offcanvas offcanvas-end offcanvas-end--big-panel d-flex"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
      tabindex="-1"
      aria-labelledby="offcanvasRepeatersLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasPeersLabel"
          class="offcanvas-title"
        >
          <strong class="text-success">{{ peers.filter(p => !p.pivot.blacklist).length }}</strong> peers<br><small>{{ user.full_name }}</small>
        </h3>
        <button
          type="button"
          class="btn-close text-reset btn btn-icon btn-icon rounded-circle btn-secondary"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="offcanvas-body">
        <div class="table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>Evaluator</th>
                <th>Institution</th>
                <th>Proposed by</th>
                <th>Submitted</th>
                <th class="text-end">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="peer in peers.filter(p => !p.pivot.blacklist)"
                :key="'peer-table-' + peer.id"
              >
                <td>{{ peer.peer }}</td>
                <td>{{ peer.institution && peer.institution.name ? peer.institution.name : peer.institution }}</td>
                <td>{{ peer.added_by }}</td>
                <td>
                  <span
                    v-if="peer.evaluation"
                    class="badge bg-light-success"
                  >Yes</span>
                  <span
                    v-if="!peer.evaluation"
                    class="badge bg-light-danger"
                  >No</span>
                </td>
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item
                        :href="$router.resolve({name: 'researcher.administration.promotion-history.see-evaluation', query: { user: user.id, convo: convo.id, actAs: $route.query.actAs}}).href"
                        target="_blank"
                      >
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View form evaluation
                      </b-dropdown-item>
                      <b-dropdown-item
                        :href="$router.resolve({ name: 'researcher.dashboard', params: { id: peer.id }, query: { actAs: $route.query.actAs } }).href"
                        target="_blank"
                      >
                        <i
                          class="me-50"
                          data-feather="edit-3"
                        /> Edit profile
                      </b-dropdown-item>
                      <b-dropdown-item @click="deletePeer(peer.id)">
                        <i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete <span class="float-end"><i data-feather="lock" /></span>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <a
                    href=""
                    class="btn btn-icon btn-light-secondary d-block d-sm-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasBottom"
                  ><i
                    data-feather="more-vertical"
                  /></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>
  <!-- -->
</template>

<script>
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import Vue from 'vue'

export default {
  components: {
    BDropdown, BDropdownItem,
  },
  props: {
    user: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      name: null,
      aspirant: null,
      calls: {},
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/convoViewPeers',
      peers: 'users/peers',
      convo: 'convo/convo',
    }),
  },
  watch: {
    user() {
      this.$store.dispatch('users/getCurrentConvoPeers', { user: this.user.id, convo: this.convo.id }).then(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      })
    },
  },
  async mounted() {
    feather.replace({
      width: 14,
      height: 14,
    })
  },
  methods: {
    closeOffcanva() {
      this.$store.dispatch('modals/toggleConvoViewPeers', false)
    },
    deletePeer(peerId) {
      Vue.swal({
        title: 'Perform the actions on the selected peer?',
        html: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes, apply!',
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('users/setPeers', { actions: { assign: 2 }, itemIds: [peerId], researcherId: this.user.id }).then(async () => {
            this.$store.dispatch('users/getCurrentConvoPeers', { user: this.user.id, convo: this.convo.id })

            setTimeout(() => {
              feather.replace({
                width: 14,
                height: 14,
              })
            }, 200)
          })
        }
      })
    },
  },
}
</script>
